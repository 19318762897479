
  import { Component, Vue } from 'vue-property-decorator'
  import logOut from '@/components/LogOut.vue'
  import GeneralLoading from '@/components/core/GeneralLoading.vue'
  import { GENIO_RELOADING } from '@/utils/general'

@Component({
  components: {
    GeneralLoading,
    logOut,
  },
})
  export default class WithOutResourceView extends Vue {
  show = false;
  img = require('@/assets/genio/GENIO_permisos.png')
  logo = require('@/assets/Logotipo_Interno.svg')

  mounted (): void {
    if (
      (!localStorage.getItem(GENIO_RELOADING) ||
        localStorage.getItem(GENIO_RELOADING) === 'false') && this.$router &&
      this.$router.options &&
      this.$router.options.routes &&
      this.$router.options.routes.length === 2
    ) {
      localStorage.setItem(GENIO_RELOADING, 'true')
      location.reload()
    }
    setTimeout(() => {
      this.show = true
    }, 2500)
  }
  }
